import { useState } from 'react';
import { Box, Button, Flex, Input, ModalCloseButton, ModalHeader, Radio, RadioGroup, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesCreate } from '../../../../services/return-codes';
import colors from '../../../../styles/colors';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodeCreateRequest } from '../../context/model/ReturnCodeCreate.request';
import { ReturnCodeType } from '../../context/model/ReturnCodeType';
import { handleGracePeriodInputChange } from '../../utils';
import { handleDownloadMultipleReturnQrPosters } from '../ReturnCodesCreated/handleDownloadMultipleReturnQrPosters';

type ReturnCodesCreateMultipleCodesModalContentProps = {
  locations: { locationId: string; locationName: string }[];
  refetchLocations: () => void;
  refetchReturnCodes: () => void;
};

export const ReturnCodesCreateMultipleCodesModalContent = ({
  locations,
  refetchLocations,
  refetchReturnCodes,
}: ReturnCodesCreateMultipleCodesModalContentProps) => {
  const translations = useTranslations();
  const [returnCodeType, setReturnCodeType] = useState<ReturnCodeType>(ReturnCodeType.REGULAR);
  const [gracePeriod, setGracePeriod] = useState<number | undefined>();
  const { handleCloseCreateModal, handleCloseCreateModalAfterCreate } = useReturnCodesContext();

  const { mutate: createRestaurantsReturnCodes } = useReturnCodesCreate();

  return (
    <>
      <ModalHeader>
        {translations('return_codes_create_multi_modal_header', {
          '{{locationCount}}': locations.length.toString(),
        })}
      </ModalHeader>
      <ModalCloseButton />
      <Flex h="100%" display="column" p={4}>
        <Box borderRadius="md" padding={4} bg={colors.grey[40]} mb={'10px'} fontSize={'small'}>
          {translations('return_codes_create_multi_modal_description')}
        </Box>
        <Box borderRadius="md" padding={4} mb={'5px'} fontSize={'small'}>
          <Text fontWeight={'bold'}>{translations('return_codes_create_modal_create_return_code_name')}</Text>
          <Text>{translations('return_codes_create_multi_modal_code_names')}</Text>
        </Box>
        <Box borderRadius="md" padding={4} mb={'5px'} fontSize={'small'}>
          <Text fontWeight={'bold'}>{translations('return_codes_create_modal_create_select_code_type')}</Text>
          <RadioGroup
            onChange={(val: ReturnCodeType) => {
              setGracePeriod(undefined);
              setReturnCodeType(val);
            }}
            value={returnCodeType}
            mb={'2'}
          >
            <Radio size="sm" value={ReturnCodeType.REGULAR} mr={'5'}>
              {translations('return_codes_create_modal_regular_code_type')}
            </Radio>
            <Radio size="sm" value={ReturnCodeType.OPEN_HOURS}>
              {translations('return_codes_create_modal_oh_code_type')}
              {'*'}
            </Radio>
          </RadioGroup>
          <Text color={colors.grey[200]}>
            {translations('return_codes_create_modal_create_select_code_type_additional_info')}
          </Text>
        </Box>
        <Box
          hidden={returnCodeType !== ReturnCodeType.OPEN_HOURS}
          borderRadius="md"
          padding={4}
          mb={'5px'}
          fontSize={'small'}
        >
          <Text fontWeight={'bold'}>{translations('return_codes_create_modal_create_grace_period')}</Text>
          <Input type={'number'} onChange={handleGracePeriodInputChange(setGracePeriod)} value={gracePeriod}></Input>
          <Text color={colors.grey[200]}>
            {translations('return_codes_create_modal_create_grace_period_additional_info')}
          </Text>
        </Box>

        <Button
          width={'100%'}
          variant="orangeSolid"
          display={'box'}
          onClick={() => {
            const request: ReturnCodeCreateRequest = {
              restaurantIds: locations.map((l) => l.locationId),
              type: returnCodeType,
              gracePeriod: gracePeriod || null,
            };

            createRestaurantsReturnCodes(
              { request },
              {
                onSuccess: (data) => {
                  handleDownloadMultipleReturnQrPosters(data);
                  refetchLocations();
                  refetchReturnCodes();
                  handleCloseCreateModalAfterCreate();
                },
              },
            );
          }}
          mb={2}
        >
          {translations('return_codes_create_modal_create_new_code')}
        </Button>

        <Button width={'100%'} colorScheme={'gray'} display={'box'} onClick={handleCloseCreateModal}>
          {translations('return_codes_create_modal_cancel')}
        </Button>
      </Flex>
    </>
  );
};
