import { ChangeEvent } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { getIconPath } from '../../../../common/components/Navbar/Menu/utils/menuHelper';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodesCreate } from '../ReturnCodesCreate/ReturnCodesCreate';
import { ReturnCodesFiltersModal } from '../ReturnCodesFilters/ReturnCodesFiltersModal';
import { ReturnCodesHowItWorksModal } from '../ReturnCodesHowItWorks/ReturnCodesHowItWorksModal';
import { FilterBadge } from './FiltersBadge';

type ReturnCodesFiltersModalProps = {
  display: string[];
};

export const ReturnCodesTableMediumHeading = ({ display }: ReturnCodesFiltersModalProps) => {
  const {
    returnCodesState,
    handleSetSearchLocationText,
    selectedFiltersCount,
    checkedAllLocations,
    handleAllLocationsCheck,
  } = useReturnCodesContext();
  const translations = useTranslations();
  const { onClose, isOpen, onOpen } = useDisclosure();

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleSetSearchLocationText(event.target.value);
  };

  const handleAllLocationsCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleAllLocationsCheck(e.target.checked);
  };

  return (
    <Flex width="100%" direction="column" display={display}>
      <Flex width="100%" direction="column" bg="beige.50" p={2}>
        <Flex width="100%" justifyContent="space-between">
          <Flex width="100%" justifyContent="flex-end" alignItems={'end'}>
            <Box width="100%" m={2}>
              <Flex align="center" gap={2} width="100%">
                <InputGroup width="100%">
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="grey.200" />
                  </InputLeftElement>
                  <Input
                    aria-label={translations('search_location')}
                    value={returnCodesState.searchLocation}
                    variant="whiteFilled"
                    placeholder={translations('return_codes_searchbox_placeholder')}
                    onChange={handleInputOnChange}
                    mr={5}
                    width="100%"
                  />
                  <Button color={'black'} bgColor={'white'} onClick={onOpen} pl={'40px'} pr={'40px'}>
                    <Image src={getIconPath('filters.svg')} mr={'10px'}></Image>
                    <Text>Filters</Text>
                    <FilterBadge count={selectedFiltersCount}></FilterBadge>
                  </Button>
                  <ReturnCodesFiltersModal isOpen={isOpen} onClose={onClose}></ReturnCodesFiltersModal>
                </InputGroup>
                <Flex p={2} marginLeft={'auto'}>
                  <ReturnCodesHowItWorksModal />
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <Flex width="100%" direction="column" bg="white" p={2} paddingBottom={0}>
        <Flex direction={'row'}>
          <Flex marginLeft={4} marginRight={4}>
            <Checkbox
              colorScheme="orange"
              isChecked={checkedAllLocations}
              onChange={handleAllLocationsCheckHandler}
            ></Checkbox>
          </Flex>
          <Flex m={2} marginLeft={'auto'} marginRight={4}>
            <ReturnCodesCreate />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
