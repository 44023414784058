import { RestaurantCategoryPricingResponse } from '../../../../Restaurants/types';

export const convertFeeInMinUnitToMainUnit = (feeInMinUnit: number): number => feeInMinUnit / 100;
export const convertFeeInMainUnitToMinUnit = (feeInMainUnit: number): number => feeInMainUnit * 100;

export const getMinAndMaxFeeValues = (restaurantPricing: RestaurantCategoryPricingResponse[]) => {
  const values = restaurantPricing
    .map((categoryPricing) => categoryPricing.notReturnFee)
    .filter((value) => value > 0)
    .reduce(
      (acc, num) => ({
        min: num > 0 ? Math.min(acc.min, num) : acc.min,
        max: Math.max(acc.max, num),
      }),
      { min: Infinity, max: -Infinity },
    );
  if (values.min === values.max) {
    return [convertFeeInMinUnitToMainUnit(values.min)];
  }
  return [convertFeeInMinUnitToMainUnit(values.min), convertFeeInMinUnitToMainUnit(values.max)].filter(
    (value) => value > 0 && value < Infinity,
  );
};
