import React, { ChangeEvent } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Image, Input, InputGroup, InputLeftElement, Text, useDisclosure } from '@chakra-ui/react';

import { getIconPath } from '../../../../common/components/Navbar/Menu/utils/menuHelper';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodesCreate } from '../ReturnCodesCreate/ReturnCodesCreate';
import { ReturnCodesFiltersModal } from '../ReturnCodesFilters/ReturnCodesFiltersModal';
import { ReturnCodesHowItWorksModal } from '../ReturnCodesHowItWorks/ReturnCodesHowItWorksModal';
import { FilterBadge } from './FiltersBadge';

type ReturnCodesFiltersModalProps = {
  display: string[];
};

export const ReturnCodesTableHeading = ({ display }: ReturnCodesFiltersModalProps) => {
  const { returnCodesState, handleSetSearchLocationText, selectedFiltersCount } = useReturnCodesContext();
  const translations = useTranslations();
  const { onClose, isOpen, onOpen } = useDisclosure();

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleSetSearchLocationText(event.target.value);
  };

  return (
    <Flex width="100%" direction="column" display={display}>
      <Flex width="100%" direction="column" bg="beige.50" p={2}>
        <Flex width="100%" justifyContent="space-between">
          <Flex width="50%">
            <Box width="100%" flex={2} m={2}>
              <Flex direction="column" gap={4} width="100%">
                <Flex align="center" gap={2} width="100%">
                  <InputGroup width="100%">
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="grey.200" />
                    </InputLeftElement>
                    <Input
                      aria-label={translations('search_location')}
                      value={returnCodesState.searchLocation}
                      variant="whiteFilled"
                      placeholder={translations('return_codes_searchbox_placeholder')}
                      onChange={handleInputOnChange}
                      mr={5}
                      width="100%"
                    />
                    <Button color={'black'} bgColor={'white'} onClick={onOpen} pl={'35px'} pr={'35px'}>
                      <Image src={getIconPath('filters.svg')} mr={'10px'}></Image>
                      <Text>{translations('return_codes_heading_filters_button')}</Text>
                      <FilterBadge count={selectedFiltersCount}></FilterBadge>
                    </Button>
                    <ReturnCodesFiltersModal isOpen={isOpen} onClose={onClose}></ReturnCodesFiltersModal>
                  </InputGroup>
                </Flex>
                <Flex align="center" gap={2} width="100%"></Flex>
              </Flex>
            </Box>
            <Box width="100%" flex={1} m={2}></Box>
          </Flex>
          <Flex display={['block', 'block', 'block', 'block', 'block']} justifyContent="flex-end" alignItems={'end'}>
            <Flex direction={'row'}>
              <Flex width={['100%', '100%', '100%', '100%']} m={2}>
                <ReturnCodesCreate />
              </Flex>
              <Flex width={['100%', '100%', '100%', '100%']} m={2}>
                <ReturnCodesHowItWorksModal />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
