import { ChangeEvent } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  useDisclosure,
} from '@chakra-ui/react';

import { getIconPath } from '../../../../common/components/Navbar/Menu/utils/menuHelper';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import colors from '../../../../styles/colors';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodesCreate } from '../ReturnCodesCreate/ReturnCodesCreate';
import { ReturnCodesFiltersModal } from '../ReturnCodesFilters/ReturnCodesFiltersModal';
import { ReturnCodesHowItWorksModal } from '../ReturnCodesHowItWorks/ReturnCodesHowItWorksModal';

type ReturnCodesFiltersModalProps = {
  display: string[];
};

export const ReturnCodesTableMobileHeading = ({ display }: ReturnCodesFiltersModalProps) => {
  const {
    returnCodesState,
    handleSetSearchLocationText,
    selectedFiltersCount,
    checkedAllLocations,
    handleAllLocationsCheck,
  } = useReturnCodesContext();
  const translations = useTranslations();
  const { onClose, isOpen, onOpen } = useDisclosure();

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleSetSearchLocationText(event.target.value);
  };

  const handleAllLocationsCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleAllLocationsCheck(e.target.checked);
  };

  return (
    <Flex width="100%" direction="column" display={display}>
      <Flex width="100%" direction="column" bg="beige.50" p={2} paddingBottom={0}>
        <Flex width="100%" justifyContent="space-between">
          <Flex width="100%">
            <Box width="100%" m={2}>
              <Flex direction="column" gap={4} width="100%">
                <Flex align="center" gap={2} width="100%">
                  <InputGroup width="100%">
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="grey.200" />
                    </InputLeftElement>
                    <Input
                      aria-label={translations('search_location')}
                      value={returnCodesState.searchLocation}
                      variant="whiteFilled"
                      placeholder={translations('return_codes_searchbox_placeholder')}
                      onChange={handleInputOnChange}
                      mr={5}
                      width="100%"
                    />
                    <Button color={'black'} bgColor={'white'} onClick={onOpen}>
                      <Image src={getIconPath('filters.svg')}></Image>
                      {selectedFiltersCount > 0 && (
                        <Badge
                          position="absolute"
                          top="-1"
                          right="-1"
                          bg={colors.orange['500']}
                          color="black"
                          width="20px"
                          height="20px"
                          borderRadius="full"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          ml="6px"
                          px="6px"
                          fontSize="small"
                          fontWeight={'normal'}
                        >
                          {selectedFiltersCount}
                        </Badge>
                      )}
                    </Button>
                    <ReturnCodesFiltersModal isOpen={isOpen} onClose={onClose}></ReturnCodesFiltersModal>
                  </InputGroup>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>

        <Flex width="100%" p={2}>
          <ReturnCodesHowItWorksModal />
        </Flex>
      </Flex>
      <Flex width="100%" direction="column" bg="white" p={2} paddingBottom={0}>
        <Flex direction={'row'}>
          <Flex marginLeft={4} marginRight={4}>
            <Checkbox
              colorScheme="orange"
              isChecked={checkedAllLocations}
              onChange={handleAllLocationsCheckHandler}
            ></Checkbox>
          </Flex>
          <Flex m={2} marginLeft={'auto'}>
            <ReturnCodesCreate />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
