import { LanguagesConfig } from '../../contexts/LocalizationContext/types';

export interface RestaurantDetails {
  id?: string;
  restaurantName: string;
  restaurantAddress: string;
  restaurantDetail: string;
  phoneNumber: string;
  websiteLink: string;
  metaCategories: MetaCategory[];
  restaurantOpeningHours: RestaurantOpeningHours[];
  overrideOpeningHours: boolean;
  images: SortedImage[];
}

export interface PublicFile {
  file: {
    id: string;
    fileKey: string;
    url: string;
  };
  order: number;
}

export interface RestaurantImagesResponse {
  keyAccountGroupImages: PublicFile[];
  restaurantImages: PublicFile[];
}

export interface MetaCategory {
  id: string;
  translations: LanguagesConfig;
  color: string | null;
  image: string | null;
  type: MetaCategoryType | null;
}

export enum MetaCategoryType {
  STANDARD = 'STANDARD',
  SPECIAL = 'SPECIAL',
  HIDDEN = 'HIDDEN',
}

export interface RestaurantOpeningHours {
  day: number;
  periods: { day: number; openTime: string | null; closeTime: string | null }[];
}

export interface SortedImage {
  id: string;
  order: number;
}

export interface RestaurantDraft {
  restaurantName: string;
  restaurantAddress: string;
  restaurantDetail: string;
  phoneNumber: string | null;
  websiteLink: string | null;
  metaCategoriesIds: string[];
  restaurantOpeningHours: RestaurantOpeningHours[] | null;
  overrideOpeningHours: boolean;
  sortedImages: SortedImage[];
  id?: string;
}

export enum RestaurantDraftStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export interface RestaurantCategoryPricingResponse {
  categoryId: string;
  notReturnFee: number;
}
