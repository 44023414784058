const API_PATH = '/api/v1/fnb';
const AUTH_API_PATH = '/api/web/auth';

export const apiPaths = {
  // AUTH
  SIGN_IN_PATH: `${AUTH_API_PATH}/signin`,
  SEND_RESET_PASSWORD_EMAIL_PATH: `${AUTH_API_PATH}/user/password/reset/token`,
  RESET_PASSWORD_PATH: `${AUTH_API_PATH}/user/password/reset`,

  // API
  GAVE_OUT_RELEVOS_QUANTITY_PATH: `${API_PATH}/restaurants/relevos/count`,
  APPLICATION_USERS_PATH: `${API_PATH}/users`,
  RELEVOS_HISTORY_PATH: `${API_PATH}/restaurants/relevos/history`,
  RELEVOS_HISTORY_EXPORT_PATH: `${API_PATH}/restaurants/relevos/history-export`,
  RESTAURANT_USER_PATH: `${API_PATH}/restaurant-user`,
  RESTAURANT_USER_NOTIFICATION_SETTINGS_PATH: `${API_PATH}/restaurant-user/notification-settings`,
  COMMUNITY_STATISTICS_PATH: `${API_PATH}/stats/community`,
  LOCATION_STATISTICS_PATH: `${API_PATH}/stats/locations`,
  REPORTS_STATISTICS_PATH: `${API_PATH}/stats/reports`,
  PRODUCT_CATEGORIES: `${API_PATH}/product-categories`,
  CUSTOMERS: `${API_PATH}/customers`,
  RESTAURANTS: `${API_PATH}/restaurants`,
  RESTAURANT_IMAGES: `${API_PATH}/restaurants/:restaurantId/images`,
  RESTAURANT_PRICING: `${API_PATH}/restaurants/:restaurantId/pricing`,
  IMAGES: `${API_PATH}/images`,
  RESTAURANTS_DRAFT_BY_ID: `${API_PATH}/restaurants/:restaurantId/drafts/:draftId`,
  RESTAURANTS_DRAFTS_STATUS: `${API_PATH}/restaurants/:restaurantId/drafts/:draftId/status`,
  RESTAURANTS_DRAFTS: `${API_PATH}/restaurants/:restaurantId/drafts`,
  RESTAURANTS_DRAFTS_ACTIVE: `${API_PATH}/restaurants/:restaurantId/drafts/active`,
  DAMAGED_ITEMS_PATH: `${API_PATH}/restaurants/relevos/damaged-items`,
  RESTAURANT_USERS_SURVEYS_PATH: `${API_PATH}/restaurant-user/surveys`,
  DOCUMENTS_PATH: `${API_PATH}/documents`,
  FREQUENTLY_ASKED_QUESTIONS: `${API_PATH}/frequently-asked-questions`,
  META_CATEGORIES: `${API_PATH}/meta-categories`,
  DEPOSIT_BORROW_PATH: `${API_PATH}/restaurants/:restaurantId/deposit/borrow`,
  DEPOSIT_RETURN_PATH: `${API_PATH}/restaurants/:restaurantId/deposit/return`,
  QUICK_CONTACTS_FNB_GROUPS_PATH: `${API_PATH}/quick-contacts/fnb-groups`,
  QUICK_CONTACTS_FNB_GROUPS_FNB_GROUP_RESTAURANTS_PATH: `${API_PATH}/quick-contacts/fnb-groups/:fnbGroupId/restaurants`,
  QUICK_CONTACTS_FNB_GROUPS_FNB_GROUP_PATH: `${API_PATH}/quick-contacts/fnb-groups/:fnbGroupId`,
  QUICK_CONTACTS_RESTAURANTS_PATH: `${API_PATH}/quick-contacts/restaurants/:restaurantId`,
  QUICK_CONTACTS_FEATURE_ENABLED_PATH: `${API_PATH}/quick-contacts/is-feature-enabled`,
  APPLICATION_USERS_RESTAURANTS_UNKNOWN_ITEMS_PATH: `${API_PATH}/users/restaurants/:restaurantId/unknown-relevos`,
  APPLICATION_USERS_RESTAURANTS_UNKNOWN_ITEMS_RETURN_PATH: `${API_PATH}/users/restaurants/:restaurantId/unknown-relevos/return`,
  INVENTORY_COUNTS_PATH: `${API_PATH}/inventory-counts`,
  INVENTORY_COUNTS_INVENTORY_COUNT_PATH: `${API_PATH}/inventory-counts/:inventoryCountId`,
  INVENTORY_COUNTS_INVENTORY_COUNT_MISSING_ITEMS_PATH: `${API_PATH}/inventory-counts/:inventoryCountId/missing-items`,
  INVENTORY_COUNTS_INVENTORY_COUNT_SNAPSHOT: `${API_PATH}/inventory-counts/:inventoryCountId/snapshot`,
  INVENTORY_COUNTS_INVENTORY_COUNT_SUBMIT_PATH: `${API_PATH}/inventory-counts/:inventoryCountId/submit`,
  INVENTORY_COUNTS_RESTAURANTS_PATH: `${API_PATH}/inventory-counts/restaurants/:restaurantId`,
  RETURN_CODES_RESTAURANTS_WITH_RETURN_CODES_COUNT: `${API_PATH}/return-codes/restaurants`,
  RETURN_CODES_CITIES_FOR_FILTERS: `${API_PATH}/return-codes/cities`,
  RETURN_CODES_FOR_RESTAURANT: `${API_PATH}/return-codes/:restaurantId`,
  RETURN_CODES_BATCH_CREATE: `${API_PATH}/return-codes/batch`,
  RETURN_CODES_DEACTIVATE: `${API_PATH}/return-codes/:returnCodeId/deactivate`,
  RETTER_RELEVOS_PATH: `${API_PATH}/retter-relevos`,
  RETTER_RELEVOS_RETTER_RELEVO_PATH: `${API_PATH}/retter-relevos/:retterRelevoId`,
};

export const getRestaurantCategoriesPath = (restaurantId: string): string =>
  `${API_PATH}/restaurants/${restaurantId}/categories`;

export const getReturnRelevosPath = (restaurantId: string): string =>
  `${API_PATH}/users/restaurants/${restaurantId}/relevos/return`;
export const getUserRelevosPath = (restaurantId: string): string =>
  `${API_PATH}/users/restaurants/${restaurantId}/relevos`;
export const getUserRelevosVerifyPath = (userId: string): string => `${API_PATH}/users/${userId}/relevos/verify`;
export const getRelevoByUIdPath = (relevoUId: string): string => `${API_PATH}/relevos/${relevoUId}`;
export const getUpgradeCardPath = (userId: string, restaurantId: string): string =>
  `${API_PATH}/users/${userId}/restaurants/${restaurantId}/increase-account-balance`;
export const getUnknownRelevosPath = (restaurantId: string): string =>
  `${API_PATH}/users/restaurants/${restaurantId}/unknown-relevos`;
export const getRestaurantInventoryPath = (restaurantId: string): string =>
  `${API_PATH}/restaurants/${restaurantId}/inventory`;
export const getRestaurantInventoryReorderPath = (restaurantId: string): string =>
  `${API_PATH}/restaurants/${restaurantId}/inventory/reorder`;

// Other
export const WP_NEWS_URL = `https://relevo.app/wp-json/wp/v2/rpp-news?per_page=100&_fields[]=id&_fields[]=date&_fields[]=title&_fields[]=content&_fields[]=yoast_head_json`;
