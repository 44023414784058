import React, { JSX, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { RelevoTableContainer } from 'common/components/Table/TableHelper';
import { StatusCodes } from 'http-status-codes';

import { getApplicationUsers, SearchUserType } from '../../../../api/applicationUser';
import { upgradeCard } from '../../../../api/relevos';
import { FullPageLoader } from '../../../../common/components/Loader/FullPageLoader';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import { useRestaurantPricing } from '../../../../services/restaurants';
import colors from '../../../../styles/colors';
import { UPGRADE_CARD_MAX_VALUE, UPGRADE_CARD_MIN_VALUE, UPGRADE_CARD_STEP } from './utils/upgrade.consts';
import { convertFeeInMinUnitToMainUnit, getMinAndMaxFeeValues } from './utils/upgrade.helper';

const acceptButtonDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: colors.orange[500] },
  _hover: { backgroundColor: colors.orange[500] },
  _active: { backgroundColor: colors.orange[500] },
};

const Upgrade = (): JSX.Element | null => {
  const { applicationUser, restaurantId, restaurant, setApplicationUser } = useScannedRelevosContext();
  const { data: restaurantPricing, isLoading: isLoadingPricing } = useRestaurantPricing(restaurantId);
  const upgradeValues = useMemo(() => {
    if (restaurantPricing) {
      return getMinAndMaxFeeValues(restaurantPricing);
    }
  }, [restaurantPricing]);
  const translations = useTranslations();
  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const restaurantCurrency = useMemo(() => restaurant?.currency, [restaurant]);
  const isCurrencyValid = useMemo(
    () => restaurantCurrency === applicationUser?.accountInfo?.accountBalance.currency,
    [applicationUser, restaurantCurrency],
  );
  const showCurrencyWarning = useMemo(
    () => !!restaurantCurrency && !!applicationUser?.accountInfo && !isCurrencyValid,
    [applicationUser?.accountInfo, isCurrencyValid, restaurantCurrency],
  );
  useEffect(() => {
    if (!restaurantId) {
      setSelectedValue(null);
    } else if (restaurantId && upgradeValues) {
      setSelectedValue(upgradeValues ? upgradeValues[0] : null);
    }
  }, [restaurantId, upgradeValues]);

  const sendCardUpgradeRequest = () => {
    if (restaurantId) {
      upgradeCard(applicationUser!.id, selectedValue!, restaurantId)
        .then(() => {
          toast.success(
            <h2>
              {translations('client')}
              <b>{applicationUser!.userId}</b>
              {translations('successfully_upgraded')}
            </h2>,
          );
          setSelectedValue(null);
          getApplicationUsers(applicationUser!.userId, SearchUserType.Card).then((applicationUsers) => {
            if (applicationUsers?.length === 1) {
              setApplicationUser(applicationUsers[0]);
            } else {
              setApplicationUser(null);
            }
          });
        })
        .catch((error: AxiosError) => {
          toast.error(
            <b>
              {translations(
                error.response?.status === StatusCodes.NOT_FOUND
                  ? 'card_upgrade_inactive_card_error'
                  : 'something_went_wrong',
              )}
            </b>,
          );
        });
    }
  };

  const handleSelectValue = useCallback((value: number) => () => setSelectedValue(value), []);

  if (applicationUser) {
    return (
      <FullPageLoader show={isLoadingPricing}>
        <Box pl={2} textAlign="left" ml="10px">
          <h4 style={{ fontSize: '20px' }}>
            {Number(applicationUser.accountInfo?.accountBalance.amount) === 0
              ? translations('relevos_upgrade_activate_header')
              : translations('relevos_upgrade_header')}
            <b>{applicationUser.userId.toString()}</b>
          </h4>
          {showCurrencyWarning ? (
            <Flex bg="red.100" p={2}>
              <WarningIcon w={10} h={6} />
              <Text>{translations('upgrade_card_invalid_currency_error')}</Text>
            </Flex>
          ) : null}

          {applicationUser.accountInfo?.accountBalance ? (
            <RelevoTableContainer>
              <Table width="500px">
                <Tbody>
                  <Tr>
                    <Td>{translations('relevos_upgrade_card_value')}</Td>
                    <Td>
                      <b>
                        {applicationUser.accountInfo?.accountBalance.amount}{' '}
                        {applicationUser.accountInfo?.accountBalance.currency}{' '}
                      </b>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>{translations('relevos_upgrade_borrowed_value')}</Td>
                    <Td>
                      <b>
                        {convertFeeInMinUnitToMainUnit(applicationUser.accountInfo?.notReturnFeeSum || 0).toFixed(2)}{' '}
                        {applicationUser.accountInfo?.accountBalance.currency}{' '}
                      </b>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>{translations('relevos_upgrade_free_value')}</Td>
                    <Td>
                      <b>
                        {(
                          Number(applicationUser.accountInfo?.accountBalance.amount) -
                          Number(convertFeeInMinUnitToMainUnit(applicationUser.accountInfo?.notReturnFeeSum) || 0)
                        ).toFixed(2)}{' '}
                        {applicationUser.accountInfo?.accountBalance.currency}{' '}
                      </b>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </RelevoTableContainer>
          ) : null}
          <br />
          {restaurantId ? (
            <>
              <Heading as="h2" fontWeight="normal" fontSize="16px" color={colors.black} textAlign="left">
                {translations('upgrade_explanation_line_1')} <br />
                {translations('upgrade_explanation_line_2')}
              </Heading>
              <Flex textAlign="left" mt={3} wrap="wrap">
                {upgradeValues?.map((value) => (
                  <Button
                    width="120px"
                    key={value}
                    padding={10}
                    mr={2}
                    mt={2}
                    fontWeight={selectedValue === value ? 'bold' : 'normal'}
                    border="1px solid"
                    borderColor={selectedValue === value ? colors.orange[500] : colors.grey[50]}
                    bgColor={colors.white}
                    onClick={handleSelectValue(value)}
                    _hover={{ bg: colors.white }}
                    _focus={{
                      boxShadow: 'none',
                    }}
                    _active={{
                      borderColor: colors.orange[500],
                    }}
                  >
                    {(restaurantCurrency || '') + ' ' + value}
                  </Button>
                ))}
                <Box pr={2} mt={2}>
                  <InputGroup size="lg" fontSize={16}>
                    <InputLeftAddon fontSize={16} children={restaurantCurrency || ''} style={{ height: '82px' }} />
                    <NumberInput
                      step={UPGRADE_CARD_STEP}
                      onChange={(valueString) => setSelectedValue(Number(valueString))}
                      value={selectedValue || undefined}
                      min={UPGRADE_CARD_MIN_VALUE}
                      max={UPGRADE_CARD_MAX_VALUE}
                      width="150px"
                    >
                      <NumberInputField style={{ height: '82px' }} />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </InputGroup>
                </Box>
              </Flex>
              <Box width="100%" textAlign="left" mt={2} ml="-18px">
                <Button
                  {...acceptButtonDefaultProps}
                  isDisabled={!selectedValue || !restaurantId || !isCurrencyValid}
                  color={colors.black}
                  bgColor={colors.orange[500]}
                  fontWeight="normal"
                  minWidth={144}
                  margin="auto"
                  mx={4}
                  mt={5}
                  onClick={sendCardUpgradeRequest}
                >
                  {Number(applicationUser.accountInfo?.accountBalance.amount) === 0
                    ? translations('card_upgrade_activate_confirm_button')
                    : translations('card_upgrade_confirm_button')}
                </Button>
              </Box>
            </>
          ) : null}
        </Box>
      </FullPageLoader>
    );
  }
  return <div />;
};

export default Upgrade;
