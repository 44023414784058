/* eslint-disable import/prefer-default-export */
import { generatePath } from 'react-router-dom';
import {
  RelevoInventoryResponse,
  RelevosProductCategoryWithImage,
  SendRestaurantInventoryReorderRequestData,
  SendRestaurantInventoryReorderResponse,
} from 'api/types';
import {
  apiPaths,
  getRestaurantCategoriesPath,
  getRestaurantInventoryPath,
  getRestaurantInventoryReorderPath,
} from 'config/apiPaths';

import { RestaurantInfo } from '../contexts/SessionContext/SessionContext';
import {
  RestaurantCategoryPricingResponse,
  RestaurantDetails,
  RestaurantImagesResponse,
} from '../modules/Restaurants/types';
import { createFormData } from './api-utils';
import axios from './axios';
import axiosInstance from './axios';

export interface PublicFile {
  id: string;
  url: string;
}

export const getRestaurantPricing = async (restaurantId: string): Promise<RestaurantCategoryPricingResponse[]> => {
  return axios
    .get<RestaurantCategoryPricingResponse[]>(generatePath(apiPaths.RESTAURANT_PRICING, { restaurantId }))
    .then(({ data }) => data);
};

export const getRestaurantCategories = async (restaurantId: string): Promise<RelevosProductCategoryWithImage[]> => {
  return axios
    .get<RelevosProductCategoryWithImage[]>(getRestaurantCategoriesPath(restaurantId))
    .then((response) => response.data);
};

export const getRestaurants = (): Promise<RestaurantInfo[]> => {
  return axios.get<RestaurantInfo[]>(`${apiPaths.RESTAURANTS}`).then(({ data }) => data);
};

export const getRestaurantInventory = async (restaurantId: string): Promise<RelevoInventoryResponse> => {
  return axios.get<RelevoInventoryResponse>(getRestaurantInventoryPath(restaurantId)).then((response) => response.data);
};

export const getRestaurantDetails = async (restaurantId: string): Promise<RestaurantDetails> => {
  return axios.get<RestaurantDetails>(`${apiPaths.RESTAURANTS}/${restaurantId}`).then(({ data }) => data);
};

export const getRestaurantImages = async (restaurantId: string): Promise<RestaurantImagesResponse> => {
  return axios
    .get<RestaurantImagesResponse>(generatePath(apiPaths.RESTAURANT_IMAGES, { restaurantId }))
    .then(({ data }) => data);
};

export const postRestaurantInventoryReorder = async (
  restaurantId: string | null,
  requestData: SendRestaurantInventoryReorderRequestData,
): Promise<SendRestaurantInventoryReorderResponse> => {
  if (!restaurantId) {
    throw new Error('restaurantId is required');
  }
  return axios
    .post<SendRestaurantInventoryReorderResponse>(getRestaurantInventoryReorderPath(restaurantId), requestData)
    .then((response) => response.data);
};

export const addRestaurantImage = async (restaurantId: string, image: File): Promise<PublicFile> => {
  const formData = createFormData({ file: image });
  return axiosInstance
    .post(`${apiPaths.IMAGES}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data);
};
