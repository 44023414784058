import { generatePath } from 'react-router-dom';
import { AssignedRelevosResponse, CategoryQuantity, RelevoInterface } from 'api/types';
import {
  apiPaths,
  getRelevoByUIdPath,
  getReturnRelevosPath,
  getUnknownRelevosPath,
  getUpgradeCardPath,
  getUserRelevosPath,
  getUserRelevosVerifyPath,
} from 'config/apiPaths';

import { Paginated } from '../common/models/paginated';
import { Language } from '../contexts/LocalizationContext/types';
import { HistoryQueryRequest } from '../modules/History/model/HistoryQueryRequest';
import { HistoryResponse } from '../modules/History/model/HistoryResponse';
import { QuickContactType } from '../modules/QuickContacts/model/enums/quick-contact-type.enum';
import { ReturnUnknownRelevosRequest } from '../modules/RelevoAssignment/model/return-unknown-relevos-request';
import { ReturnUnknownRelevosResponse } from '../modules/RelevoAssignment/model/return-unknown-relevos-response';
import { UnknownRelevosListResponse } from '../modules/RelevoAssignment/model/unknown-relevos-list-response';
import axios from './axios';

export const getRelevoByUId = async (uId: string): Promise<RelevoInterface> => {
  return axios.get<RelevoInterface>(getRelevoByUIdPath(uId)).then((response) => response.data);
};

export const assignRelevos = async (
  id: string,
  relevos: string[],
  restaurantId: string,
  quickContactType?: QuickContactType,
): Promise<AssignedRelevosResponse> => {
  const requestData = {
    id,
    type: quickContactType || QuickContactType.USER,
    items: relevos,
  };

  return axios
    .post<AssignedRelevosResponse>(getUserRelevosPath(restaurantId), requestData)
    .then((response) => response.data);
};

export const assignUnknownRelevos = async (
  userId: string,
  categories: CategoryQuantity,
  restaurantId: string,
): Promise<number> => {
  const requestData = {
    categories,
    userId,
  };

  return axios.post(getUnknownRelevosPath(restaurantId), requestData).then((response) => response.data);
};

export const returnRelevos = async (relevosUids: string[], restaurantId: string): Promise<AssignedRelevosResponse> =>
  axios.post(getReturnRelevosPath(restaurantId), { items: relevosUids }).then((response) => response.data);

export const upgradeCard = async (userId: string, amount: number, restaurantId: string): Promise<void> =>
  axios.post(getUpgradeCardPath(userId, restaurantId), { amount: amount.toString() }).then((response) => response.data);

export const verifyRelevosForUser = async ({
  userId,
  uId,
  previouslyScannedProductsUids,
  restaurantId,
}: {
  userId: string;
  uId: string;
  previouslyScannedProductsUids: string[];
  restaurantId: string;
}): Promise<RelevoInterface[]> => {
  return axios
    .get<RelevoInterface[]>(getUserRelevosVerifyPath(userId), {
      params: { previouslyScannedProductsUids, uId, restaurantId },
    })
    .then((response) => response.data);
};

export const getRelevosHistory = async (
  historyQueryParams: HistoryQueryRequest,
  offset: number,
  limit: number,
): Promise<Paginated<HistoryResponse>> => {
  return axios
    .get<Paginated<HistoryResponse>>(apiPaths.RELEVOS_HISTORY_PATH, {
      params: { ...historyQueryParams, offset, limit },
    })
    .then((response) => response.data);
};

export const getRelevosHistoryExport = async (
  queryParams: HistoryQueryRequest,
  language: Language,
): Promise<string> => {
  return axios
    .get<string>(apiPaths.RELEVOS_HISTORY_EXPORT_PATH, {
      params: queryParams,
      responseType: 'blob',
      headers: { 'x-localization': language },
    })
    .then((response) => response.data);
};

export const getUnknownRelevosToReturn = (restaurantId: string): Promise<UnknownRelevosListResponse[]> => {
  return axios
    .get(generatePath(apiPaths.APPLICATION_USERS_RESTAURANTS_UNKNOWN_ITEMS_PATH, { restaurantId }))
    .then(({ data }) => data);
};

export const returnUnknownRelevos = (
  restaurantId: string,
  request: ReturnUnknownRelevosRequest,
): Promise<ReturnUnknownRelevosResponse> => {
  return axios
    .post(generatePath(apiPaths.APPLICATION_USERS_RESTAURANTS_UNKNOWN_ITEMS_RETURN_PATH, { restaurantId }), request)
    .then(({ data }) => data);
};
