import { Language, LanguagesConfig } from '../contexts/LocalizationContext/types';
import { FailureObjectInformationResponse } from './utils/failure-object-information-response';

export enum SupertokensApiErrorStatus {
  FIELD_ERROR = 'FIELD_ERROR',
  GENERAL_ERROR = 'GENERAL_ERROR',
  WRONG_CREDENTIALS_ERROR = 'WRONG_CREDENTIALS_ERROR',
  RESET_PASSWORD_INVALID_TOKEN_ERROR = 'RESET_PASSWORD_INVALID_TOKEN_ERROR',
}

export enum UserType {
  APP = 'app',
  CARD_ACTIVE = 'card_active',
  CARD_PASSIVE = 'card_passive',
  DEPOSIT = 'deposit',
  REUSE_CARD = 'reuse_card',
  QUICK_CONTACT = 'quick_contact',
}

export enum SupertokensApiSuccessStatus {
  OK = 'OK',
}

export const responseStatusToTranslationKey = {
  [SupertokensApiErrorStatus.FIELD_ERROR]: 'sign_in_invalid_fields',
  [SupertokensApiErrorStatus.GENERAL_ERROR]: 'something_went_wrong',
  [SupertokensApiErrorStatus.WRONG_CREDENTIALS_ERROR]: 'sign_in_invalid_fields',
  [SupertokensApiErrorStatus.RESET_PASSWORD_INVALID_TOKEN_ERROR]: 'reset_passowrd_invalid_token',
};

export interface SupertokensSignInApiResponseSuccess {
  status: SupertokensApiSuccessStatus;
  user: { id: string; email: string };
}

export interface SupertokensApiGeneralResponseSuccess {
  status: SupertokensApiSuccessStatus;
}

export interface SupertokensApiResponseFailure {
  status: SupertokensApiErrorStatus;
  message?: string;
}

export interface RelevoInterface {
  id: string;
  productName: string;
  uId: string;
  category: {
    id: string;
    translations: LanguagesConfig;
    categoryGroup: string;
    imageUrl?: string | null;
  };
}

export interface ApplicationUser {
  id: string;
  userId: string;
  userType: UserType;
  accountInfo?: {
    accountBalance: {
      amount: string;
      currency: string;
    };
    notReturnFeeSum: number;
  };
}

export interface AssignedRelevosErrorInformationResponse extends FailureObjectInformationResponse {
  uId: string;
  productName: string | null;
}

export interface AssignedRelevosResponse {
  assignedItems: string[];
  notAssignedItems: AssignedRelevosErrorInformationResponse[];
}

export interface RelevosProductCategoryWithImage {
  id: string;
  translations: LanguagesConfig;
  imageUrl: string | null;
}

export interface UserProfileResponse {
  restaurantNames: string[];
  email: string;
  firstName: string | null;
  lastName: string | null;
}

export interface RestaurantUserRequest {
  language: Language;
}

export interface RestaurantUserResponse {
  language: Language;
}

export interface ResetPasswordRequestData {
  password: string;
  token: string;
}

export interface WordpressNewsResponse {
  id?: number;
  date?: string;
  title?: {
    rendered?: string;
  };
  content?: {
    rendered?: string;
  };
  yoast_head_json?: {
    og_image?: [
      {
        url?: string;
      },
    ];
  };
}

export interface SendEmailToRelevoResponse {
  emailSent: boolean;
}

export interface SendRestaurantInventoryReorderRequestData {
  subject: string;
  body: string;
}

export interface SendRestaurantInventoryReorderResponse {
  messageSent: boolean;
}

export interface CategoryQuantity {
  [categoryId: string]: number;
}

export interface CategorySummary {
  translations: LanguagesConfig;
  restaurantItemsCount: number;
  givenOutItemsCount: number;
  pendingReturnItemsCount: number;
  startingDate: string | null;
  imageUrl: string | null;
}

export interface RelevoInventoryResponse {
  restaurantId: string;
  categoriesSummary: CategorySummary[];
}
