import { Button, Tooltip } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';

type CreateButtonProps = {
  buttonText: string;
  checkedLocationsCount: number;
  handleOpenCreateModal: () => void;
  tooltipLabel: string;
};

const ButtonWithTooltipIfInactive = ({
  buttonText,
  checkedLocationsCount,
  handleOpenCreateModal,
  tooltipLabel,
}: CreateButtonProps) => {
  return checkedLocationsCount === 0 ? (
    <Tooltip label={tooltipLabel}>
      <Button
        isDisabled={checkedLocationsCount === 0}
        variant="orangeSolid"
        onClick={handleOpenCreateModal}
        width={'100%'}
        whiteSpace={'normal'}
        minWidth={'300px'}
      >
        {buttonText}
      </Button>
    </Tooltip>
  ) : (
    <Button
      isDisabled={checkedLocationsCount === 0}
      variant="orangeSolid"
      onClick={handleOpenCreateModal}
      width={'100%'}
      whiteSpace={'normal'}
      minWidth={'300px'}
    >
      {buttonText}
    </Button>
  );
};

export const ReturnCodesCreate = () => {
  const translations = useTranslations();
  const { returnCodesState, handleOpenCreateModal } = useReturnCodesContext();
  const checkedLocationsCount = returnCodesState.selectedLocations.length;

  const buttonText =
    checkedLocationsCount === 1
      ? translations('return_codes_create_return_code_for_single_location_button')
      : translations('return_codes_create_return_code_for_locations_button', {
          '{{locationCount}}':
            checkedLocationsCount > 0
              ? checkedLocationsCount.toString()
              : translations('return_codes_create_return_code_for_locations_button_multiple'),
        });

  return (
    <>
      <ButtonWithTooltipIfInactive
        buttonText={buttonText}
        checkedLocationsCount={checkedLocationsCount}
        handleOpenCreateModal={handleOpenCreateModal}
        tooltipLabel={translations('return_codes_create_return_code_for_locations_button_hover')}
      ></ButtonWithTooltipIfInactive>
    </>
  );
};
